import {Component, OnInit} from '@angular/core';
import {
  ListViewResults,
  ListViewSettings,
  ListViewTableColumnType,
} from '../../interfaces/list-view-models';
import {StoresService} from '../../services/stores.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {ServerResponse} from '../../interfaces/server-response';
import {DbCurrencyModel, PaymentSuppliers} from '../../../../../shared/db-models/payments';
import {EditCurrencyModalComponent} from '../../modals/edit-currency-modal/edit-currency-modal.component';

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
})
export class CurrenciesComponent implements OnInit {
  isLoading = true;

  listViewSettings: ListViewSettings = {
    allowCreate: true,
    allowEdit: true,
    allowGalleryMode: false,
    createButtonText: 'Create New Currency',
    isGalleryDefault: false,
    hideSearch: true,
    tableSettings: {
      columns: [
        {
          name: 'id',
          displayText: 'Id',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'name',
          displayText: 'Name',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'sign',
          displayText: 'Sign',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'paymentSupplier',
          displayText: 'Payment Supplier',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
          parser: (currency: DbCurrencyModel) => {
            return PaymentSuppliers[currency.paymentSupplier];
          },
        },
      ],
      showExpand: false,
    },
    defaultSortColumn: 'id',
    getData: () => this.getData(),
    create: () => this.create(),
    edit: (currency: DbCurrencyModel) => this.edit(currency),
  };

  constructor(private storesService: StoresService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  private getData(): Observable<ServerResponse<ListViewResults<DbCurrencyModel>>> {
    const result = new Subject<ServerResponse<ListViewResults<DbCurrencyModel>>>();

    this.storesService.getAllCurrencies().subscribe(
      (response) => {
        const currencies = response.docs.map((x) => {
          const currStore = x.data();
          currStore.id = x.id;
          return currStore;
        });

        result.next({
          result: true,
          data: {
            data: currencies,
            dataCount: currencies.length,
          },
        });
        result.complete();
      },
      (err) => {
        result.error(err);
        result.complete();
      }
    );

    return result.asObservable();
  }

  private create(): Observable<boolean> {
    const createResult = new Subject<boolean>();

    const modal = this.modalService.open(EditCurrencyModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'dark-modal ltr',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    (modal.componentInstance as EditCurrencyModalComponent).currencyId = '';

    modal.result
      .then(
        () => {
          createResult.next(true);
        },
        () => {
          createResult.next(false);
        }
      )
      .catch(() => {
        createResult.next(false);
      })
      .finally(() => {
        createResult.complete();
      });

    return createResult;
  }

  private edit(currency: DbCurrencyModel): Observable<boolean> {
    const createResult = new Subject<boolean>();

    const modal = this.modalService.open(EditCurrencyModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'dark-modal ltr',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    (modal.componentInstance as EditCurrencyModalComponent).currencyId = currency.id ?? '';

    modal.result
      .then(
        () => {
          createResult.next(true);
        },
        () => {
          createResult.next(false);
        }
      )
      .catch(() => {
        createResult.next(false);
      })
      .finally(() => {
        createResult.complete();
      });

    return createResult;
  }
}
