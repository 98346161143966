import {Injectable} from '@angular/core';
import {AngularFirestore, QuerySnapshot} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Timestamp, arrayUnion} from '@angular/fire/firestore';
import {Observable, Subject, first, from, map, switchMap, withLatestFrom} from 'rxjs';
import {DbCurrencyModel} from '../../../../shared/db-models/payments';
import {DbSessionModel} from '../../../../shared/db-models/session';
import {DbStoreModel} from '../../../../shared/db-models/store';
import {CSV_Strategy} from '../../../../shared/ecommerce-platform-integration/csv-strategies';
import {IECommercePlatformIntegration} from '../../../../shared/ecommerce-platform-integration/ecommerce-platform-integration';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  addProducts(storeId: string, strategy: CSV_Strategy, products: unknown[]) {
    return from(import('../../../../shared/utilities/integration')).pipe(
      switchMap((m) => {
        const {convertProductsRowsToTerrificFormat} = m;
        return convertProductsRowsToTerrificFormat(strategy(products, storeId));
      }),
      switchMap(({terrificProducts, terrificVariants}) => {
        const batch = this.firestore.firestore.batch();

        terrificProducts.forEach((product) =>
          batch.set(this.firestore.doc(`products/${product.id}`).ref, product)
        );

        terrificVariants.forEach((variant) =>
          batch.set(
            this.firestore.doc(`products/${variant.productId}/productVariants/${variant.id}`).ref,
            variant
          )
        );

        return from(batch.commit()).pipe(map(() => terrificProducts.length));
      })
    );
  }
  constructor(private firestore: AngularFirestore, private fns: AngularFireFunctions) {}

  // region Stores

  public getAllStores(): Observable<QuerySnapshot<DbStoreModel>> {
    return this.firestore.collection<DbStoreModel>(`stores`).get();
  }

  public getStoreById(id: string) {
    return this.firestore.doc<DbStoreModel>(`stores/${id}`).get();
  }
  public getSessions() {
    const stores = this.getAllStores().pipe(
      map((stores) => stores.docs.map((store) => store.data()))
    );
    return this.firestore
      .collection<DbSessionModel>(`sessions`)
      .get()
      .pipe(
        map((query) => query.docs),
        map((docs) => docs.map((doc) => doc.data())),
        withLatestFrom(stores),
        map(([sessions, stores]) =>
          sessions.map((session) => ({
            ...session,
            storeName: stores.find((store) => store.id === session.storeId)?.name,
          }))
        ),
        map((sessions) =>
          sessions.map((session) => ({
            id: session.id,
            name: `${session.storeName} - ${session.hostName} - ${session.startTime
              .toDate()
              .toLocaleString()}`,
          }))
        ),
        first()
      );
  }

  public setStoreWriteOnlyDoc(storeId: string, docName: string, data: unknown) {
    console.log('storeId: string, docName: string, data: unknown', storeId, docName, data);
    const doc = this.firestore.doc<unknown>(`stores/${storeId}/write-only/${docName}`);
    if (!data) return from(doc.delete().catch((err) => console.log(err)));
    return from(doc.set(data, {merge: true}));
  }

  public getStoreReadOnlyDoc<T>(storeId: string, docName: string) {
    console.log('storeId: string, docName: string, data: unknown', storeId, docName);
    return this.firestore
      .doc<T>(`stores/${storeId}/write-only/${docName}`)
      .get()
      .pipe(map((doc) => (doc.exists ? doc.data() : undefined)));
  }

  public getIdForNewStore() {
    return this.firestore.collection(`stores`).doc().ref.id;
  }

  /**
   * Creates or updates a store and returns it's id
   * @param store The store object
   */
  public updateStore(store: DbStoreModel) {
    return from(this.firestore.doc(`stores/${store.id}`).set(store, {merge: true})).pipe(
      switchMap(async () => {
        if (!store.externalUrl) return null;
        const hostName = new URL(store.externalUrl).hostname;
        const hasShopifyHandle = await fetch(`https://${hostName}/products.json?limit=1`)
          .then(() => true)
          .catch(() => false);
        if (!hasShopifyHandle) return null;
        const data: IECommercePlatformIntegration = {
          createDate: Timestamp.now(),
          storeUrl: hostName,
          terrificStoreIds: arrayUnion(store.id) as unknown as string[],
          type: 'anonymous-shopify',
          id: `anonymous-shopify-${hostName}`,
        };
        return this.firestore
          .doc(`ecommercePlatformIntegrations/${data.id}`)
          .set(data, {merge: true});
      }),
      map(() => store.id)
    );
  }

  // endregion

  // region Currencies

  public getAllCurrencies() {
    return this.firestore.collection<DbCurrencyModel>(`currencies`).get();
  }

  public getCurrencyById(id: string) {
    return this.firestore.doc<DbCurrencyModel>(`currencies/${id}`).get();
  }

  /**
   * Creates or updates a currency and returns it's id
   * @param currency The currency object
   */
  public updateCurrency(currency: DbCurrencyModel): Observable<string> {
    const subject = new Subject<string>();

    currency = Object.assign({}, currency);

    if (currency.id) {
      this.firestore
        .doc(`currencies/${currency.id}`)
        .update(currency)
        .then(() => {
          subject.next(currency.id ?? '');
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    } else {
      const doc = this.firestore.collection(`currencies`).doc();
      currency.id = doc.ref.id;
      doc
        .set(currency)
        .then(() => {
          subject.next(currency.id);
        })
        .catch((err) => {
          subject.error(err);
        })
        .finally(() => {
          subject.complete();
        });
    }

    return subject.asObservable();
  }

  // endregion

  public syncFromProductsFeed({storeId, feedUrl}: {storeId: string; feedUrl: string}) {
    return this.fns.httpsCallable('syncFromProductsFeed')({feedUrl, storeId});
  }
}
