import {Component, Input, OnInit, inject, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {NgForm} from '@angular/forms';
import TwoRaffleHelpers from '../../helpers/helpers';
import {catchError, filter, finalize, map, switchMap, takeUntil} from 'rxjs/operators';
import {
  CoriunderAppKeys,
  CustomTheme,
  DbStoreModel,
  StockManagePolicy,
  StoreCredential,
} from '../../../../../shared/db-models/store';
import {StoresService} from '../../services/stores.service';
import {environment} from 'src/environments/environment';
import {DbCurrencyModel, PaymentSuppliers} from '../../../../../shared/db-models/payments';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-edit-territory-modal',
  templateUrl: './edit-store-modal.component.html',
})
export class EditStoreModalComponent implements OnInit, OnDestroy {
  httpClient = inject(HttpClient);
  isLoading = true;
  isSubmitting: boolean;
  destroyed$ = new Subject<void>();

  public errorMessage = '';
  public PaymentSuppliers = PaymentSuppliers;

  currencies: DbCurrencyModel[] = [];

  managementListPolicy: Record<StockManagePolicy, string> = {
    'by-checkout': 'By checkout',
    'by-add-to-cart': 'By add to cart',
    'no-stock-management': 'No stock management',
  };

  private _currency: DbCurrencyModel;
  private _stockManagementPolicy: StockManagePolicy;

  get currency() {
    return this._currency;
  }
  set currency(currency: DbCurrencyModel) {
    this._currency = currency;
    this.store.currency = currency.isoCode;
    this.store.currencyId = currency.id;
  }

  get stockManagementPolicy() {
    return this._stockManagementPolicy;
  }
  set stockManagementPolicy(stockManagementPolicy: StockManagePolicy) {
    this._stockManagementPolicy = stockManagementPolicy;
    this.store.stockManagementPolicy = stockManagementPolicy;
  }

  get showStoreTokenField() {
    return environment.client.clientType === 't1';
  }

  public get token() {
    return this.t1Credentials$.value?.token;
  }
  public set token(value) {
    const prev = this.t1Credentials$.value;
    this.t1Credentials$.next({
      ...prev,
      token: value ?? '',
    });
  }
  public get externalUrl() {
    return this.t1Credentials$.value?.externalUrl || this.store.externalUrl;
  }
  public set externalUrl(value) {
    const prev = this.t1Credentials$.value;
    this.store.externalUrl = value;
    this.t1Credentials$.next({
      ...prev,
      externalUrl: value ?? '',
    });
  }
  public t1Credentials$ = new BehaviorSubject<StoreCredential>({externalUrl: '', token: ''});
  public isT1CredentialsAreValid$ = this.t1Credentials$.pipe(
    filter((Credentials) => !!Credentials.externalUrl && !!Credentials.token),
    switchMap((cred) => this.validateT1Credentials(cred))
  );

  @Input() storeId: string = '';

  store = new DbStoreModel();
  updateCoriunder = false;
  coriunder: CoriunderAppKeys = {
    personalhashkey: '',
    merchantnumber: '',
  };

  get haveTheme() {
    return !!this.store.theme;
  }
  set haveTheme(value: boolean) {
    if (value) {
      this.store.theme = this.store.theme ?? ({} as DbStoreModel['theme']);
    } else {
      this.store.theme = {} as DbStoreModel['theme'];
    }
  }
  get theme() {
    return this.store.theme ?? ({} as CustomTheme);
  }
  set theme(theme: CustomTheme) {
    this.store.theme = theme;
  }

  constructor(
    public modal: NgbActiveModal,
    private storesService: StoresService,
    private toastr: ToastrService
  ) {}

  public readonly HOST_FROM_URL_REGEX =
    /^(([^@:\/\s]+):\/?)?\/?(([^@:\/\s]+)(:([^@:\/\s]+))?@)?([^@:\/\s]+)(:(\d+))?(((\/\w+)*\/)([\w\-\.]+[^#?\s]*)?(.*)?(#[\w\-]+)?)?$/;

  ngOnInit() {
    if (!this.storeId) {
      this.isLoading = false;
      this.store.id = this.storesService.getIdForNewStore();
      this.storeId = this.store.id;
      this.stockManagementPolicy = 'by-checkout';
      this.loadCurrencies()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((currencies: DbCurrencyModel[]) => (this.currencies = currencies));
      return;
    }

    this.loadStoreAndCurrencies();
    this.storesService
      .getStoreReadOnlyDoc<StoreCredential>(this.storeId, environment.client.clientType)
      .subscribe((storeCredentials) => {
        if (storeCredentials) this.t1Credentials$.next(storeCredentials);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadStoreAndCurrencies(): void {
    forkJoin({
      store: this.storesService.getStoreById(this.storeId),
      currencies: this.loadCurrencies(),
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (values) => {
          this.currencies = values.currencies;
          const store = values.store;
          if (!store.exists) {
            this.toastr.error("Can't load store.");
            this.modal.dismiss();
          } else {
            Object.assign(this.store, store.data());
            this.store.id = this.storeId;
            const currency = this.currencies.find((cur: DbCurrencyModel) => {
              return cur.id === this.store.currencyId;
            });
            if (currency) {
              this.currency = currency;
            }

            this.stockManagementPolicy = this.store.stockManagementPolicy ?? 'by-checkout';

            this.isLoading = false;
          }
        },
        error: () => {
          // TODO: Show Error
          this.modal.dismiss();
        },
      });
  }

  private loadCurrencies(): Observable<DbCurrencyModel[]> {
    return this.storesService
      .getAllCurrencies()
      .pipe(map((res) => res.docs.map((doc) => doc.data())));
  }

  public save(f: NgForm): void {
    if (f.invalid) {
      TwoRaffleHelpers.markFormInvalidAndFocus(f);
    } else {
      this.isSubmitting = true;
      f.form.disable();

      this.storesService
        .updateStore(Object.assign({}, this.store))
        .pipe(
          finalize(() => {
            this.isSubmitting = false;
            f.form.enable();
          })
        )
        .subscribe(
          () => {
            this.toastr.success('Action was completed successfully');
            this.modal.close();
          },
          (err) => {
            this.toastr.error('Failed to save store. ' + err.message);
          }
        );
      if (this.updateCoriunder)
        this.storesService
          .setStoreWriteOnlyDoc(this.storeId, 'coriunder', this.coriunder)
          .subscribe();
      const t1Credentials = this.t1Credentials$.getValue();
      if (
        environment.client.clientType === 't1' &&
        typeof t1Credentials?.externalUrl === 'string' &&
        this.HOST_FROM_URL_REGEX.test(t1Credentials?.externalUrl)
      ) {
        this.t1Credentials$.next({
          externalUrl:
            t1Credentials.externalUrl.match(this.HOST_FROM_URL_REGEX)?.[7] ??
            t1Credentials.externalUrl,
          token: t1Credentials.token,
        });
        this.storesService
          .setStoreWriteOnlyDoc(
            this.storeId,
            environment.client.clientType,
            this.t1Credentials$.value
          )
          .subscribe();
      }
    }
  }

  public delete(): void {}
  validateT1Credentials(cred: StoreCredential | null) {
    if (!cred) return of(false);
    const url = cred.externalUrl.match(this.HOST_FROM_URL_REGEX)?.[7];
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        user: `${cred.token}`,
        timestamp: String(Date.now()),
      }),
    };
    return this.httpClient
      .get<{
        'type': number;
        'data': {
          'message': string;
        };
      }>('https://' + url + '/api/products', httpOptions)
      .pipe(
        map((res) => {
          this.errorMessage = res?.data?.message ?? '';
          return res.type === 1;
        }),
        catchError((e) => {
          if (e instanceof HttpErrorResponse) {
            this.errorMessage = e.error?.data?.message ?? e.message ?? e.statusText ?? '';
          }
          return of(false);
        })
      );
  }
}
