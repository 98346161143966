import {Component, OnDestroy, ViewChild} from '@angular/core';
import {
  ListViewResults,
  ListViewSettings,
  ListViewTableColumnType,
} from '../../interfaces/list-view-models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, Subscription} from 'rxjs';
import {ServerResponse} from '../../interfaces/server-response';
import {ListViewComponent} from '../../components/list-view/list-view.component';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '../../services/users.service';
import {DbUserModel} from '../../../../../shared/db-models/user';
import {AppService} from '../../services/app.service';
import {AddAdminUserModalComponent} from '../../modals/add-admin-user-modal/add-admin-user-modal.component';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
})
export class AdminUsersComponent implements OnDestroy {
  isLoading = true;

  connectedUser;

  listViewSettings: ListViewSettings = {
    allowCreate: true,
    allowEdit: false,
    allowGalleryMode: false,
    createButtonText: 'Add Admin User',
    isGalleryDefault: false,
    hideSearch: true,
    tableSettings: {
      columns: [
        {
          name: 'id',
          displayText: 'Id',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'displayName',
          displayText: 'Display Name',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'email',
          displayText: 'Email',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
      ],
      showExpand: false,
    },
    defaultSortColumn: 'id',
    getData: () => this.getData(),
    create: () => this.create(),
  };
  @ViewChild(ListViewComponent, {static: false}) private listView: ListViewComponent;

  private subsCleanup: Subscription[] = [];

  constructor(
    private usersService: UsersService,
    private appService: AppService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.connectedUser = this.usersService.getConnectedUserSync();
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    for (const currSub of this.subsCleanup) {
      currSub.unsubscribe();
    }
  }

  private getData(): Observable<ServerResponse<ListViewResults<DbUserModel>>> {
    const result = new Subject<ServerResponse<ListViewResults<DbUserModel>>>();

    this.usersService.getAllSuperAdmins().subscribe(
      (users: DbUserModel[]) => {
        result.next({
          result: true,
          data: {
            data: users,
            dataCount: users.length,
          },
        });
        result.complete();
      },
      (err) => {
        result.error(err);
        result.complete();
      }
    );

    return result.asObservable();
  }

  private create(): Observable<boolean> {
    const createResult = new Subject<boolean>();

    const modal = this.modalService.open(AddAdminUserModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'dark-modal',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    modal.result
      .then(
        () => {
          createResult.next(true);
        },
        () => {
          createResult.next(false);
        }
      )
      .catch(() => {
        createResult.next(false);
      })
      .finally(() => {
        createResult.complete();
      });

    return createResult.asObservable();
  }

  public removeUser(user: DbUserModel): void {
    this.appService
      .showConfirm('Confirm Delete', 'Are you sure you want to remove this user?')
      .subscribe((result: boolean) => {
        if (result) {
          this.usersService.removeUserAsSuperAdmin(user.id).subscribe(
            (_: any) => {
              this.listView.refreshData();
            },
            (err) => {
              this.toastr.error('Failed to remove the user.' + (err?.message ? err.message : ''));
            }
          );
        }
      });
  }
}
